import Chart from 'react-apexcharts'
import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axios';
import '../styles/chart.css'
import 'react-circular-progressbar/dist/styles.css';
import EvolutionConfirmationPopup from './evolutionConfirmPopup';
import { ReactComponent as ArrowUpRight } from '../svgs/arrow-up-right.svg';
import { ReactComponent as ArrowDownLeft } from '../svgs/charts/arrow-down-left.svg';
import { ReactComponent as Blob } from '../svgs/blob.svg';
import PropTypes from 'prop-types';
import iconRisk from '../svgs/icon-risk.svg';


const Evolution = ({ patientId }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [ipssData, setIpssData] = useState(null);
  const [psatData, setPsatData] = useState(null);
  const [psaltData, setPsaltData] = useState(null);
  const [volumeData, setVolumeData] = useState(null);
  const [loboData, setLoboData] = useState(null);
  const [piradsData, setPiradsData] = useState(null);
  const [qmaxData, setQmaxData] = useState(null);
  const [residueData, setResidueData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [updateChart, setUpdateChart] = useState(false);
  const [shouldRenderChart, setShouldRenderChart] = useState(true);

  const [chartOptions, setChartOptions] = useState({});

  const deleteData = (name, data) => {
    axios.delete(`/clinical/${patientId}/${name}/${data}`)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error('Error deleting clinical data:', error);
      }
    );
  };

  const handleDelete = (name, data) => {
    switch (name) {
      case 'IPSS':
        deleteData('IpssData', data);
        setIpssData(ipssData.filter((item) => item.value !== data));
        break;
      case 'PSA Total':
        deleteData('PsatData', data);
        setPsatData(psatData.filter((item) => item.value !== data));
        break;
      case 'PSA Livre':
        deleteData('PsaltData', data);
        setPsaltData(psaltData.filter((item) => item.value !== data));
        break;
      case 'Volume da Próstata':
        deleteData('VolumeData', data);
        setVolumeData(volumeData.filter((item) => item.value !== data));
        break;
      case 'Lobo Médio':
        deleteData('LoboData', data);
        setLoboData(loboData.filter((item) => item.value !== data));
        break;
      case 'PI-RADS':
        deleteData('PiradsData', data);
        setPiradsData(piradsData.filter((item) => item.value !== data));
        break;
      case 'Qmax':
        deleteData('QmaxData', data);
        setQmaxData(qmaxData.filter((item) => item.value !== data));
        break;
      case 'Res.pós-miccional':
        deleteData('ResidueData', data);
        setResidueData(residueData.filter((item) => item.value !== data));
        break;
      default:
        break;
    }

    setShouldRenderChart(false);
    setTimeout(() => {
      setShouldRenderChart(true);
    }, 0);

    setIsDeleting(false);
    setUpdateChart(!updateChart);
  };

  const createChart = useCallback(() => {

    const getIpssOldestDate = () => {
      if (ipssData && ipssData.length > 0) {
        return ipssData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getIpssLatestDate = () => {
      if (ipssData && ipssData.length > 0) {
        return ipssData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getPsatOldestDate = () => {
      if (psatData && psatData.length > 0) {
        return psatData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getPsatLatestDate = () => {
      if (psatData && psatData.length > 0) {
        return psatData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
  
    const getPsaltOldestDate = () => {
      if (psaltData && psaltData.length > 0) {
        return psaltData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
  
    const getPsaltLatestDate = () => {
      if (psaltData && psaltData.length > 0) {
        return psaltData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getVolumeOldestDate = () => {
      if (volumeData && volumeData.length > 0) {
        return volumeData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getVolumeLatestDate = () => {
      if (volumeData && volumeData.length > 0) {
        return volumeData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getLoboOldestDate = () => {
      if (loboData && loboData.length > 0) {
        return loboData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getLoboLatestDate = () => {
      if (loboData && loboData.length > 0) {
        return loboData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getPiradsOldestDate = () => {
      if (piradsData && piradsData.length > 0) {
        return piradsData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getPiradsLatestDate = () => {
      if (piradsData && piradsData.length > 0) {
        return piradsData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getQmaxOldestDate = () => {
      if (qmaxData && qmaxData.length > 0) {
        return qmaxData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getQmaxLatestDate = () => {
      if (qmaxData && qmaxData.length > 0) {
        return qmaxData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };
  
    const getResidueOldestDate = () => {
      if (residueData && residueData.length > 0) {
        return residueData.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
        });
      }
    };
  
    const getResidueLatestDate = () => {
      if (residueData && residueData.length > 0) {
        return residueData.reduce((latest, current) => {
          return latest.date > current.date ? latest : current;
        }
        );
      }
    };

    const getOldestDate = () => {
      const ipssOldestDate = getIpssOldestDate();
      const psatOldestDate = getPsatOldestDate();
      const psaltOldestDate = getPsaltOldestDate();
      const volumeOldestDate = getVolumeOldestDate();
      const loboOldestDate = getLoboOldestDate();
      const piradsOldestDate = getPiradsOldestDate();
      const qmaxOldestDate = getQmaxOldestDate();
      const residueOldestDate = getResidueOldestDate();
  
      const dateObjects = [
          ipssOldestDate,
          psatOldestDate,
          psaltOldestDate,
          volumeOldestDate,
          loboOldestDate,
          piradsOldestDate,
          qmaxOldestDate,
          residueOldestDate
      ];
  
      const validDates = dateObjects.filter(dateObj => dateObj && dateObj.date);
  
      if (validDates.length === 0) {
          return null; 
      }
  
      const oldestDate = validDates.reduce((oldest, current) => {
          return oldest.date < current.date ? oldest : current;
      });
  
      return oldestDate;
  };
  
  
  const getLatestDate = () => {
    const ipssLatestDate = getIpssLatestDate();
    const psatLatestDate = getPsatLatestDate();
    const psaltLatestDate = getPsaltLatestDate();
    const volumeLatestDate = getVolumeLatestDate();
    const loboLatestDate = getLoboLatestDate();
    const piradsLatestDate = getPiradsLatestDate();
    const qmaxLatestDate = getQmaxLatestDate();
    const residueLatestDate = getResidueLatestDate();

    const dateObjects = [
        ipssLatestDate,
        psatLatestDate,
        psaltLatestDate,
        volumeLatestDate,
        loboLatestDate,
        piradsLatestDate,
        qmaxLatestDate,
        residueLatestDate
    ];

    const validDates = dateObjects.filter(dateObj => dateObj && dateObj.date);

    if (validDates.length === 0) {
        return null; 
    }

    const latestDate = validDates.reduce((latest, current) => {
        return latest.date > current.date ? latest : current;
    });

    return latestDate;
};


    if (!ipssData && !psatData && !psaltData && !volumeData && !loboData && !piradsData && !qmaxData && !residueData) return;

    setChartOptions({
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            offsetX: -50,
            show: true,
            tools: {
              download:'<svg width="20" height="20" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 24H17.5902" stroke="black" stroke-width="1.39" stroke-linecap="round"/><path d="M9.29592 17.5902V1M9.29592 1L2.50903 8.16393M9.29592 1L15.7058 8.16393" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
              selection: true | '<img src="../svgs/" width="20">',
              zoom: '<svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="25" height="25" fill="white"/><path d="M11.1408 20.7244C16.5875 20.7244 21.003 16.3089 21.003 10.8622C21.003 5.41545 16.5875 1 11.1408 1C5.69408 1 1.27863 5.41545 1.27863 10.8622C1.27863 16.3089 5.69408 20.7244 11.1408 20.7244Z" stroke="black" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round" fill="white"/><path d="M18.2409 17.9629L24.1582 23.8802" stroke="black" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.1408 7.3125V14.4133" stroke="black" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.6918 10.8623H7.591" stroke="black" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round"/></svg>',
              zoomin: false,
              zoomout: false,
              pan: true,
              reset:'<svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" fill="white"/><path d="M3.97396 23.8803H20.5424C22.2854 23.8803 23.6983 22.4674 23.6983 20.7244V8.88979L12.2582 1L0.818054 8.88979V20.7244C0.818054 22.4674 2.23102 23.8803 3.97396 23.8803Z" fill="white" stroke="#080708" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.70862 18.3561C8.70862 16.6131 10.1216 15.2002 11.8645 15.2002H12.6535C14.3965 15.2002 15.8094 16.6131 15.8094 18.3561V23.8789H8.70862V18.3561Z" fill="white" stroke="#080708" stroke-width="1.32002" stroke-linecap="round" stroke-linejoin="round"/></svg>',
              customIcons: []
            },
          },
          events: {
            mounted: (chartContext) => {
              if (chartContext) {
                chartContext.hideSeries('PSA Total');
                chartContext.hideSeries('PSA Livre');
                chartContext.hideSeries('Volume da Próstata');
                chartContext.hideSeries('PI-RADS');
                chartContext.hideSeries('Qmax');
                chartContext.hideSeries('Res.pós-miccional');
              }
            },
            markerClick: function(event, chartContext, opts) {

              const seriesIndex = opts.seriesIndex;
              const dataPointIndex = opts.dataPointIndex; 

              const clickedDataName = chartContext.w.config.series[seriesIndex].name;
              const clickedData = chartContext.w.config.series[seriesIndex].data[dataPointIndex];

              setName(clickedDataName);
              setData(clickedData);
              setIsDeleting(true);
            }
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.5,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100]
          }
        },
        colors: ['#CFFF51', '#F3B3C7', '#D8D8D8', '#8282FF', '#98ECFF', '#F8D57D', '#FF837B', '#6882F2'],
        tooltip: {
          x: {
            enabled: false,
            format: "dd MMM yyyy"
          },
          y: {
            enabled: false,
          },
          theme: 'dark',
        },
        xaxis: {
          type: 'datetime',
          min: new Date(getOldestDate().date).getTime(),
          max: new Date(getLatestDate().date).getTime(),
          labels: {
            format: "MMM'yyyy",
          },
        },
        yaxis: [
          {
            min: 0,
            max: 35,
            seriesName: 'IPSS',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              align: 'right',
              style: {
                colors: '#424242',
                cssClass: 'y-axis',
              }
            },
            title: {
              text: "IPSS",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            },
          },
          {
            min: 0,
            max: 20,
            seriesName: 'PSAT',
            opposite: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '##424242',
              }
            },
            title: {
              text: "PSA Total",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            },
          },
          {
            min: 0,
            max: 50,
            seriesName: 'PSALT',
            opposite: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "PSA Livre/PSA Total",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
          {
            min: 20,
            max: 250,
            seriesName: 'volume',
            opposite: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "Volume da Próstata",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
          {
            min: 0,
            max: 2.5,
            seriesName: 'lobo',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "Lobo Médio",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
          {
            min: 1,
            max: 5,
            seriesName: 'pirads',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "PI-RADS",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
          {
            min: 0,
            max: 30,
            seriesName: 'qmax',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "Qmax",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
          {
            min: 0,
            max: 500,
            seriesName: 'residuo',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#424242'
            },
            labels: {
              style: {
                colors: '#424242',
              },
            },
            title: {
              text: "Resíduo Pós-Miccional",
              style: {
                cssClass: 'axis-title',
                fontFamily: 'Outfit',
              }
            }
          },
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        markers: {
          size: 5, 
          strokeWidth: 5, 
          shape: 'circle', 
        },
      },
      series: [{
        name: 'IPSS',
        type: 'area',
        data: ipssData.map((data) => [new Date(data.date).getTime(), data.value]) 
      }, {
        name: 'PSA Total',
        type: 'area',
        data: psatData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'PSA Livre',
        type: 'area',
        data: psaltData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'Volume da Próstata',
        type: 'area',
        data: volumeData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'Lobo Médio',
        type: 'area',
        data: loboData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'PI-RADS',
        type: 'area',
        data: piradsData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'Qmax',
        type: 'area',
        data: qmaxData.map((data) => [new Date(data.date).getTime(), data.value])
      }, {
        name: 'Res.pós-miccional',
        type: 'area',
        data: residueData.map((data) => [new Date(data.date).getTime(), data.value])
      }
      ],

      legend: {
        horizontalAlign: 'center',
        offsetX: 40,
        fontFamily: 'Outfit', 
        onItemClick: {
          toggleDataSeries: true
        },
      }
    });
  }, [ipssData, psatData, psaltData, volumeData, loboData, piradsData, qmaxData, residueData]);


  useEffect(() => {

    setIsLoading(true);
    setTimeout(() => {
      axios.get(`/clinical/${patientId}`)
        .then(response => {
            setIpssData(response.data.ipss);
            setPsatData(response.data.psat);
            setPsaltData(response.data.psalt);
            setVolumeData(response.data.volume);
            setLoboData(response.data.lobo);
            setPiradsData(response.data.pirads);
            setQmaxData(response.data.qmax);
            setResidueData(response.data.residue);
      })
      .catch(error => {
        console.error('Error fetching clinical data:', error);
      });
    }, 10);
  }, [patientId, updateChart]);


  useEffect(() => {
    if (
      (ipssData && ipssData.length > 0) ||
      (psatData && psatData.length > 0) ||
      (psaltData && psaltData.length > 0) ||
      (volumeData && volumeData.length > 0) ||
      (loboData && loboData.length > 0) ||
      (piradsData && piradsData.length > 0) ||
      (qmaxData && qmaxData.length > 0) ||
      (residueData && residueData.length > 0)
    ) {
      createChart();
      setIsLoading(false);
    }
  }
    , [createChart, isLoading, ipssData, psatData, psaltData, volumeData, loboData, piradsData, qmaxData, residueData, updateChart, shouldRenderChart]);

  return (
    <div className='flex flex-row h-full gap-4'>
      <div id="chart" className={`relative bg-white rounded-4xl py-4 pb-0 ${isExpanded ? 'w-full' : 'w-[75%]'}`}>
        <h2 className="text-3xl font-normal ml-4">Evolução Clínica</h2>
        {!isLoading && shouldRenderChart && (ipssData || psatData || psaltData || volumeData || loboData || piradsData || qmaxData || residueData) &&  (
          <Chart
            key={JSON.stringify(chartOptions)}
            options={chartOptions.options}
            series={chartOptions.series}
            type="area"
            width="100%"
            height="90%"
          />
        )}
        <button onClick={() => setIsExpanded(!isExpanded)}
          className={`
          absolute top-6
          right-5
          rounded-full w-8 h-8
          bg-cream-tabs
          flex items-center justify-center
          hover:bg-black hover:text-white
          `}>
          {isExpanded && (
            <ArrowDownLeft className='w-5 h-5' />
          )}
          {!isExpanded && (
            <ArrowUpRight className='w-5 h-5' />
          )}
        </button>
      </div>
      {!isExpanded && (
        <div className=' flex-grow flex flex-col justify-end items-center rounded-4xl bg-image relative overflow-hidden'>
          <Blob className='absolute h-full -top-2' />
          <div className='h-1/2 w-full flex justify-center items-center bg-white-gradient rounded-4xl z-10 relative'>
            <a
              href="https://www.prostatecancer-riskcalculator.com/seven-prostate-cancer-risk-calculators#CalculatorContainer" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`
              absolute top-6
              right-6
              rounded-full w-12 h-12
              bg-[#313131]
              flex items-center justify-center
              `}>
              <img src={iconRisk} alt="Risk Icon" className="w-5 h-5"/>
            </a>
            <div className='flex-grow w-full flex justify-between items-center p-7'>
              <h2 className='relative w-4/10 text-3xl'>Calculadora<br/>do Risco</h2>
            </div>
          </div>
        </div>
      )}
      <EvolutionConfirmationPopup
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onDelete={handleDelete}
        name={name}
        data={data[1]} 
      />
    </div>
  )
};


export default Evolution;

Evolution.propTypes = {
  patientId: PropTypes.string.isRequired
};